import React, { Component } from 'react';
import { CCard, CCardBody, CCol, CForm, CRow, CFormGroup, CInput, CLabel, CSelect, CButton, CInputCheckbox } from '@coreui/react'
import { connect } from 'react-redux';
import { setFlashNotification } from '../../../redux/action/uiAction';
import { fetchDocumentType, fetchDocumentTypeMetaData } from '../documentType/action';
import { fetchAdvanceSearch } from './SearchAction';
import DynamicFieldHandler from '../common/components/DynamicFieldHandler';

class AdvanceSearchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documentType: '',
            metaDataTypes: [],
            selectedDocumentType: '',
            selectedDepartmentList: [],
            formdata: '',
            matchAll: true,
            ocrText: ''
        };
    }

    componentDidMount() {
        this.props.fetchDocumentType(this.props.accessToken);
    }

    onDocumentTypeSelectionChange(index) {
        let data=JSON.parse(index);
        if (data !== '' && this.props.document_type.results.length > 0) {
            let documentTypePk = data.id
            this.props.fetchDocumentTypeMetaData(this.props.accessToken, documentTypePk)
            this.setState({ documentType: documentTypePk, metaDataTypes: [], selectedDocumentType: data.value});
        }

    }

    searchDocument(event) {
        event.preventDefault();
        let query = "";
        var data = [];
        const state = this.state
        if (state.matchAll) {
            query = '_match_all=on&';
        }
        this.state.metaDataTypes.map((row, index) => {
            if (this.state.selectedDocumentType !== null) {
                data.push(row.value)
            }
        })
        if (this.state.ocrText !== '') {
            this.props.fetchAdvanceSearch(this.props.accessToken, `${query}document_type__label=${this.state.selectedDocumentType}&versions__version_pages__ocr_content__content=${this.state.ocrText}&metadata__value=${data.join(',')}`, this.props.history)
        } else {
            this.props.fetchAdvanceSearch(this.props.accessToken, `${query}document_type__label=${this.state.selectedDocumentType}&metadata__value=${data.join(',')}`, this.props.history)
        }
    }

    render() {
        // console.log("valuemin", this.props.language)
        return (
            <CForm action="" method="post" onSubmit={event => this.searchDocument(event)} encType="multipart/form-data" className="form-horizontal ">
                <CRow>
                    <CCol xs="12" md="12">
                        <h4 className="mb-3"> Search Content</h4>
                    </CCol>
                    <CCol xs="12" md="12">
                        <CCard>
                            <CCardBody>
                                <CFormGroup row>
                                    <CCol md="6" >
                                        <CLabel >{this.props.language.documentType}</CLabel>
                                        <CSelect custom name="select" id="select" onChange={event => this.onDocumentTypeSelectionChange(event.target.value)}>
                                            <option value="">{this.props.language.chooseType}</option>
                                            {/* {this.props.document_type.results && this.props.document_type.results.map((document_type, index) => (
                                                <option key={index} value={index} selected={this.state.documentType === document_type.id ? true : false}> {document_type.label}</option>
                                            ))} */}
                                            {
                                                this.props.profile.user_assing !== undefined && this.props.profile.user_assing.length > 0 && this.props.profile.user_assing.map((row, index) => {
                                                    return (
                                                        // <option key={index} value={row.document_type.label} selected={this.state.documentType === row.document_type.id ? true : false} >{row.document_type.label}</option>
                                                        <option key={index} value={JSON.stringify({ id: row.document_type.id, value: row.document_type.label })} >{row.document_type.label}</option>
                                                    )
                                                })
                                            }
                                        </CSelect>
                                    </CCol>
                                    {/* <CCol md="6">
                                        <CRow style={{ justifyContent: "end", marginTop: 20 }}>
                                            <CCol md="2">
                                                <CLabel>Match All</CLabel>
                                            </CCol>
                                            <CCol md="6">
                                                <CInputCheckbox checked={this.state.matchAll} onClick={() => { this.setState({ matchAll: !this.state.matchAll }) }} />
                                            </CCol>
                                        </CRow>
                                    </CCol> */}
                                    <CCol xs="12" md="12">
                                        <CLabel >{this.props.language.ocr}</CLabel>
                                        <CInput type="text" placeholder={this.props.language.searchTextHere} onChange={event => { this.setState({ ocrText: event.target.value }); }} />
                                    </CCol>
                                </CFormGroup>
                                <CFormGroup row>
                                    <CCol md="12">
                                        <CRow>
                                            <DynamicFieldHandler
                                                documentTypeMetaData={this.props.documentTypeMetaData}
                                                updateChange={(data) => this.setState({ metaDataTypes: data })} />
                                        </CRow>
                                    </CCol>
                                </CFormGroup>
                                <CFormGroup row>
                                    <CCol md="12" className="mt-4">
                                        <CButton type="submit" size="md" color="success" >{this.props.language.searchBtn}</CButton>
                                    </CCol>
                                </CFormGroup>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </CForm>
        )
    }
}

const mapStateToProps = state => {
    return {
        accessToken: state.userReducer.accessToken,
        document_type: state.documentTypeReducer.document_type,
        documentTypeMetaData: state.documentTypeReducer.documentTypeMetaData,
        language: state.languageTypeReducer.language,
        profile: state.userReducer.profile,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchDocumentType: (accessToken) => dispatch(fetchDocumentType(accessToken)),
        setFlashMessage: (message, color) => dispatch(setFlashNotification(message, color)),
        fetchDocumentTypeMetaData: (accessToken, documentTypePk) => dispatch(fetchDocumentTypeMetaData(accessToken, documentTypePk)),
        fetchAdvanceSearch: (accessToken, query, history) => dispatch(fetchAdvanceSearch(accessToken, query, history)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSearchPage);
