import { DEFAULT_DATA } from "../../../../library/Constants";
import { ALL_FOLDERS_LIST } from "./actionType";

const DEFAULT_DATA_TYPE = {
    status: "", data: []
}

const initialState = {
    foldersList: DEFAULT_DATA_TYPE
};

const fileManagerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_FOLDERS_LIST:
            return {
                ...state,
                foldersList: action.payload
            };
        default:
            return state;
    }
};

export default fileManagerReducer;
