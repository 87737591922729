export const HINDI = {
    permissions: 'अनुमतियां',
    state: 'स्थिति',
    transition: 'परिवर्तनकाल',
    ocr: 'ओसीआर',
    allLogs: 'सभी लॉग',
    add: 'जोड़ें',
    selectTag: 'टैग का चयन करें',
    pagesForDocument: 'दस्तावेज़ के लिए पेज',
    days: 'दिन',
    hours: 'घंटे',
    minutes: 'मिनट',
    cancel: 'रद्द करना',
    attach: 'संलग्न करें',
    setExpiryDateAndTime: 'समाप्ति तिथि और समय देखें: (अपेक्षित)',
    timeInMins: 'मिनटों में चेक किए गए दस्तावेज़ को रखने के लिए समय की मात्रा।',
    doNotallowNewVersion: 'इस दस्तावेज़ के नए संस्करण को अपलोड करने की अनुमति न दें',
    addTocheckout: 'चेकआउट में जोड़ें',
    edit: 'संपादित करें',
    delete: 'हटाएं',
    setPassword: 'पासवर्ड सेट करें',
    roleHeader: 'भूमिका बनाएँ',
    label: 'लेबल',
    department: 'विभाग',
    roleCreateBtn: 'सृजन करना',
    roleViewHeader: 'भूमिकाएँ',
    aclBtn: 'एसीएल',
    editBtn: 'संपादित करें',
    deleteBtn: 'हटाएँ',
    chooseFileBtn: 'फ़ाइल का चयन',
    importBtn: 'आयात',
    userHeader: 'उपयोगकर्ता बनाइये',
    firstName: 'पहला नाम',
    enterFirstName: 'पहला नाम दर्ज करें',
    lastName: 'उपनाम',
    enterLastName: 'अंतिम नाम दर्ज करो',
    email: 'ईमेल',
    enterEmail: 'ईमेल दर्ज करें',
    assignGroup: 'समूह सौंपें',
    userName: 'उपयोगकर्ता नाम',
    enterUserName: 'उपयोगकर्ता नाम दर्ज करें',
    password: 'कुंजिका',
    enterPassword: 'पास वर्ड दर्ज करें',
    createBtn: 'सृजन करना',
    availableUserHeader: 'उपयोगकर्ताओं',
    fullName: 'नाम',
    action: 'कार्य',
    lastLogin: 'अंतिम लॉगइन',
    departmentHeader: 'विभाग बनाएँ',
    enterDeptName: 'विभाग का नाम दर्ज करें',
    departmentNames: 'विभाग का नाम',
    userCount: 'उपयोगकर्ता गणना',
    addSubDept: 'उप विभाग जोड़ें',
    createDocumentTypeHeader: 'डॉक्यूमेंट टाइप बनाएं',
    enterDocType: 'दस्तावेज़ प्रकार दर्ज करें',
    availableDocTypeHeader: 'दस्तावेज़ प्रकार',
    documentCount: 'दस्तावेज़ों की गिनती',
    metaDataTypeBtn: 'मेटाडेटा प्रकार',
    createWorkFlowTypeHeader: 'कार्यप्रवाह  बनाएँ',
    enterLabel: 'लेबल दर्ज करें',
    name: 'नाम',
    enterName: 'नाम दर्ज',
    availableWorkFlowHeader: 'कार्यप्रवाह',
    deletedDateAndTime: 'हटाए गए दिनांक और समय',
    availableAllLogs: 'सभी लॉग',
    dateAndTime: 'दिनांक और समय',
    actor: 'अभिनेता',
    event: 'प्रतिस्पर्धा',
    target: 'लक्ष्य',
    createTagHeader: 'टैग बनाएँ',
    color: 'रंग',
    createMetadataTypesHeader: 'मेटाडेटा प्रकार बनाएँ ',
    availableMetadataTypesHeader: 'मेटाडेटा प्रकार',
    fileExplore: 'फ़ाइल अन्वेषण करें',
    bulkImport: 'थोक आयात',
    bulkExport: 'थोक निर्यात',
    queryFilter: 'क्वेरी फ़िल्टर',
    rebuildSearchIndex: 'खोज इंडेक्स का पुनर्निर्माण करें',
    fileExploreDetails: 'फ़ाइल अन्वेषण विवरण',
    documentType: 'दस्तावेज़ का प्रकार',
    documents: 'दस्तावेज़',
    date: 'दिनांक',
    view: 'राय',
    defaultSet: 'डिफॉल्ट सेट',
    images: 'इमेजिस',
    purchaseOrder: 'क्रय आदेश',
    technicalDocs: 'तकनीकी डॉक्स',
    test: 'परीक्षा',
    stagingFolder: 'मचान फोल्डर',
    stagingHome: 'मचान घर',
    availableSearchTypes: 'उपलब्ध खोज प्रकार',
    grantBtn: 'अनुदान',
    removeBtn: 'हटाना',
    fileElements: 'फ़ाइल तत्व',
    fileInformation: 'फ़ाइल जानकारी',
    restore: 'पुनर्स्थापित',
    fileName: 'फ़ाइल का नाम',
    enterFileName: 'फ़ाइल का नाम दर्ज करें',
    briefContent: 'संक्षिप्त सामग्री',
    upload: 'डालना',
    searchDocument: 'दस्तावेज़ खोजें',
    enterSearchTextArea: 'खोज पाठ क्षेत्र',
    searchBtn: 'खोज',
    advanceSearchBtn: 'उन्नत खोज',
    searchResults: 'खोज परिणाम',
    description: 'विवरण',
    addedOn: 'पर जोड़ा',
    allDocuments: 'सभी दस्तावेज़',
    select: 'चुनते हैं',
    tags: 'टैग',
    details: 'विवरण',
    download: 'डाउनलोड',
    trash: 'कचरा',
    submitForOcr: 'ओसीआर के लिए सबमिट करें',
    pages: 'पृष्ठों',
    versions: 'संस्करणों',
    comments: 'टिप्पणियाँ',
    workflow: 'कार्यप्रवाह',
    checkout: 'चेकआउट',
    checkin: 'चेक इन',
    logs: 'लॉग्स',
    properties: 'गुण',
    acls: 'एसीएल',
    createVersionHeader: 'संस्करण बनाएँ',
    files: 'फ़ाइलें',
    versionOfDocument: 'दस्तावेज़ का संस्करण',
    comment: 'टिप्पणी',
    encoding: 'एन्कोडिंग',
    mimeType: 'माइम प्रकार',
    size: 'आकार',
    onDate: 'तारीख पर',
    attachTag: 'टैग संलग्न करें',
    tagName: 'टैग नाम',
    actionType: 'प्रक्रिया का प्रकार',
    addComment: 'टिप्पणी जोड़ें',
    attachComment: 'टिप्पणी संलग्न करें',
    metaDataType: 'मेटाडाटा प्रकार',
    value: 'मूल्य',
    initialState: 'प्रारम्भिक अवस्था',
    addToCheckoutBtn: 'चेकआउट में जोड़ें',
    newAclListFor: 'नई प्रवेश नियंत्रण सूची के लिए :',
    assignRole: 'भूमिका सौंपें',
    trashDocumentsHeader: 'कचरा दस्तावेज़',
    welcome: 'स्वागत',
    recentDocumentsHeader: 'हाल के कागजात',
    addedDate: 'जोड़ा गया दिनांक',
    metaDataNameHint: 'इस मेटाडेटा प्रकार को संदर्भित करने के लिए अन्य एप्लिकेशन द्वारा उपयोग किया गया नाम.',
    metaDataLabelHint: 'इस मेटाडाटा प्रकार का संक्षिप्त विवरण.',
    metaDataLabelHintInHindi: 'इस मेटाडाटा प्रकार का संक्षिप्त विवरण करे हिंदी मे.',
    submitBtn: 'प्रस्तुत',
    chooseRole: 'भूमिका चुनें',
    enterRoleName: 'भूमिका का नाम दर्ज करें',
    chooseType: 'प्रकार चुनें',
    account: 'खाता',
    profile: 'प्रोफ़ाइल',
    logout: 'लॉग आउट',
    language: 'भाषा ',
    completion: 'समापन',
    grantPermissionTo: 'अनुमति प्रदान करें',
    grantedPermissions: 'दी गई अनुमति',
    availablePermissions: 'उपलब्ध अनुमतियाँ',
    aclHint: 'जोड़े जाने वाली प्रविष्टियों का चयन करें। कई प्रविष्टियों का चयन करने के लिए नियंत्रण रखें। चयन पूरा होने के बाद, नीचे दिए गए बटन पर क्लिक करें या क्रिया को सक्रिय करने के लिए सूची पर डबल क्लिक करें.',
    removeAll: 'सभी हटाएं',
    confirm: 'पुष्टि करें',
    yes: 'हाँ',
    editRoleName: 'रोल्स नाम संपादित करें',
    editDepartmentName: 'विभाग का नाम संपादित करें',
    closeBtn: 'बंद करे',
    updateBtn: 'अपडेट करें',
    enterGroupName: 'समूह का नाम दर्ज करें',
    preview: 'पूर्वावलोकन',
    pageNumber: 'पृष्ठ संख्या',
    rebuildCache: 'कैश का पुनर्निर्माण करें',
    editVersions: 'संस्करणों को संपादित करें',
    versionComment: 'संस्करण टिप्पणी',
    groupName: 'समूह का नाम...',
    file: 'फ़ाइलें',
    uploadAFile: 'एक फाइल अपलोड करें',
    editDocType: 'दस्तावेज़ प्रकार संपादित करें',
    initial: 'प्रारंभिक',
    states: 'स्तिथि',
    formChechLabel: 'प्रपत्र की जांच-लेबल',
    enterCompletion: 'पूर्णता दर्ज करें',
    createState: 'स्तिथि बनाएं',
    updateTag: 'अद्यतन टैग',
    editUser: 'यूजर को संपादित करो',
    originState: 'मूल स्तिथि',
    selectOriginState: 'कृपया मूल स्तिथि चुनें',
    destinationState: 'गंतव्य स्तिथि',
    condition: 'शर्त',
    newPassword: 'नया पासवर्ड',
    uploadBtn: 'अपलोड करें',
    staging: 'मंचन',
    searchTextHere: 'यहां पाठ खोजें',
    createTransition: 'क्रेटा संक्रमण',
    eventSubsciptions: 'घटना सदस्यता',
    nameSpace: 'नाम स्थान',
    subscription: 'अंशदान',
    filter: 'फ़िल्टर',
    chooseTransition: 'संक्रमण चुनें',
    user: 'उपयोगकर्ता',
    searchUser: 'उपयोगकर्ता खोजें',
    profileDetails: 'प्रोफ़ाइल विवरण',
    admin: 'व्यवस्थापक',
    changePassword: 'पासवर्ड बदलें',
    oldPassword: 'पुराना पासवर्ड',
    enterOldPassword: 'पुराना पासवर्ड डालें',
    enterNewPassword: 'नया पासवर्ड दर्ज करें ',
    confirmPassword: 'पासवर्ड की पुष्टि कीजिये',
    enterConfirmPassword: 'पासवर्ड की पुष्टि करें',
    updateTimezoneAndLanguage: 'अद्यतन समयक्षेत्र और भाषा',
    timezone: 'समय क्षेत्र',
    assign: 'समूह ',
    filterOptions: 'फ़िल्टर विकल्प',
    grantedSearchTypes: 'दी गई खोज प्रकार',
    newAclFor: 'नई एक्सेस कंट्रोल लिस्ट',
    utilities: 'उपयोगिताओं',
    addMetadataType: 'मेटाडेटा प्रकार जोड़ें',
    metadataTypes: 'मेटाडेटा प्रकार',
    chooseMetaDataType: 'मेटाडेटा प्रकार चुनें',
    required: 'अपेक्षित',
    dashboard: 'डैशबोर्ड',
    utility: 'उपयोगिता',
    setup: 'सेटअप',
    knowledgeRepository: 'ज्ञान भंडार',
    workArea: 'काम का क्षेत्र',
    search: 'खोज',
    assigneddocuments: 'दस्तावेज सौंपे',
    checkouts: 'चेकआउट',
    leave: 'छोड़ना',
    propertiesForDocument: 'दस्तावेज़ के लिए गुण',
    dateAdded: 'तारीख संकलित हुई',
    uuid: 'सार्वभौमिक अद्वितीय पहचानकर्ता',
    fileMimeType: 'फ़ाइल माइम प्रकार',
    fileEncodeing: 'फ़ाइल एन्कोडिंग',
    fileSize: 'फाइल का आकार',
    filePathInStorage: 'भंडारण में फ़ाइल पथ',
    checksum: 'चेकसम',
    pleaseWait: 'कृपया प्रतीक्षा करें..',
    savesearch: 'खोज संग्रहित करें',
    searchTemplate: 'टेम्पलेट खोजें',
    notifications: 'सूचनाएं',
    assignDepartment: 'समूह असाइन करें',
    downloadCsvButton: 'डाउनलोड उदाहरण सीएसवी',
    areYouSure: 'क्या आप वाकई यह क्रिया करना चाहते हैं ?',
    departments: 'विभागों',
    trashedDocuments: 'ट्रैश किए गए दस्तावेज़',
    workFlowDocumentType: 'वर्कफ़्लो दस्तावेज़ प्रकार',
    createWorkflowDocumentType: 'वर्कफ़्लो दस्तावेज़ प्रकार बनाएँ',
    pleaseAssignDocumentType: 'कृपया दस्तावेज़ प्रकार असाइन करें',
    trashTimeUnit: 'कचरा समय इकाई:',
    trashTimePeriod: 'कचरा समय:',
    deleteTimeUnit: 'समय इकाई हटाएं:',
    deleteTimePeriod: 'समय अवधि हटाएं:',
    enterTrashTime: 'कचरा समय दर्ज करें',
    enterDeleteTime: 'हटाएँ समय दर्ज करें',
    selectDeleteTimeUnit: 'डिलीट टाइम यूनिट का चयन करें',
    selectTrashTimeUnit: 'कचरा समय इकाई का चयन करें',
    SelectFieldType: 'फ़ील्ड प्रकार का चयन करें',
    ProvideFieldoptions: 'फ़ील्ड विकल्प प्रदान करें',
    AddMoreOptions: 'अधिक विकल्प जोड़ें',
    Hindi: 'हिंदी',
    note: '* नोट -: यदि आप पाठ बॉक्स का चयन कर रहे हैं तो कोई मान फ़ील्ड नहीं माना जाएगा',
    Seen: 'पढ़े हुए का चिह्न',
    checkoutDateTime: 'चेकआउट तिथि और समय',
    expiryDateTime: 'एक्सपायरी डेट और समय',
    blockNewVersion: 'नया संस्करण अपलोड ब्लॉक करें',
    checkoutsDetails: 'चेकआउट विवरण',
    enterNumber: 'यहां नंबर दर्ज करें',
    events: 'आयोजन',
    userAcls: 'उपयोगकर्ता एसीएल',
    documentDetails: 'दस्तावेज़ विवरण',
    tagNames: 'टैग नाम',
    movetrash: 'ट्रैश में ले जाएं',
    identifier: 'पहचान करने वाला',
    currentstage: 'वर्तमान स्थिति',
    LastTransition: 'अंतिम रूपांतरण',
    ShareLink: 'लिंक शेयर करें',
    addAssign: 'असाइन करने के लिए जोड़ें',
    readyAssign: 'असाइन करने के लिए तैयार है',
    selectfile: 'अपलोड करने के लिए एक फ़ाइल का चयन करें',
    or: 'या',
    drag: 'इस बॉक्स में एक फ़ाइल खींचें',
    recordmanagement: 'रिकॉर्ड प्रबंधन',
    documentlogs: 'दस्तावेज़ लॉग',
    complete: 'पहले ही पूरा कर लिया',
    enterComment: 'टिप्पणी दर्ज करें',
    bookmarks: 'बुकमार्क',
    selectDepartment: 'विभाग का चयन करें',
    stateDocuments: 'स्थिति की दस्तावेज़ों  ',
    chooseState: 'स्थिति चुनें',
    stateDocList: 'इस स्थिति की सभी दस्तावेज़ों',
    usersFailedToImport: 'आयात करने के लिए उपयोगकर्ता की सूची विफल रही',
    errorMsg: 'त्रुटि संदेश',
    sharedLink: 'साझा लिंक',
    createSharedLink: 'साझा लिंक बनाएं',
    availableSharedLink: 'उपलब्ध साझा लिंक ',
    startTime: 'समय शुरू',
    endTime: 'अंत समय',
    editShareLink: 'साझा लिंक संपादित करें',
    storageUsage: 'भंडारण उपयोग',
    compressLog: "दबाव लॉग",
    reports: "रिपोर्टों",
    originalFileSize: "मूल फ़ाइल का आकार",
    compressedFileSize: "संपीड़ित फ़ाइल का आकार",
    compressionCompletedOn: "संपीड़न पूरा हुआ",
    employeeId: "कर्मचारी आयडी",
    groups: "समूहों",
    group: "समूह",
    createGroup: "समूह बनाना",
    profilePicture: "प्रोफ़ाइल फोटो",
    uploadProfilePicture: "प्रोफ़ाइल चित्र अपलोड करें",
    metaData: "मेटा डेटा",
    watchLog: "फ़ोल्डर लॉग देखें",
    duplicate: "डुप्लिकेट",
    DocumentsLog: "दस्तावेज़ लॉग",
    duplicateLog: "प्रतिरूप देखें",
    documenteventLog: "दस्तावेज़घटनालॉग",
    documentStats: "पिछले 7 दिनों के दस्तावेज़ आँकड़े",
    freeSpace: "मुक्त स्थान",
    used: "उपयोग किया गया",
    corruptfileLog: "भ्रष्ट फ़ाइल लॉग",
    designation: "पदनाम",
    signature: "हस्ताक्षर",
    UpdateSignature: "हस्ताक्षर अपडेट करें",
    UploadSignature: "हस्ताक्षर अपलोड करें",
    uplaodSignAndProfilePic: "हस्ताक्षर तथा प्रोफ़ाइल फोटो अपलोड करें",
    attachDocuments: "दस्तावेज़ संलग्न करें",
    proceedWithSignature: "हस्ताक्षर के साथ आगे बढ़ें",
    proceedWithoutSignature: "हस्ताक्षर के बिना आगे बढ़ें",
    assignedDocumentType: "सौंपना दस्तावेज़ का प्रकार ",
    attachFile: "फ़ाइल जोड़ें",
    file_explorer: "फाइल ढूँढने वाला",
    mailNotifications: "मेल सूचनाएं",
    email_profile: "ईमेल प्रोफ़ाइल",
    to: "को",
    email_body: "ईमेल बॉडी",
    time: "समय",
    operator: "ऑपरेटर",
    subject: "विषय",
    mailNotifications: "मेल सूचनाएं",
}
